<template>
    <div class="container p-4">
        <div class="row-md-8 justify-content-center">
            <div class="d-flex justify-content-between w-100">
                <b-button
                    class="mr-2"
                    size="sm"
                    variant="primary"
                    @click="saveGame"
                >
                    New Game
                </b-button>
                <div>
                    <b-button
                        class="ml-2"
                        size="sm"
                        variant="outline-primary"
                        @click="updateTemplate"
                    >
                        Update Template
                    </b-button>
                    <b-button
                        :disabled="!canDeleteTemplate"
                        class="ml-2"
                        size="sm"
                        variant="outline-danger"
                        @click="deleteTemplate"
                    >
                        Delete Template
                    </b-button>
                </div>
            </div>
        </div>

        <b-tabs content-class="mt-3 min-vh-100" class="mt-3">
            <b-tab title="Main" active>
                <div v-if="loading" class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    Please wait. The game is loading...
                </div>
                <div v-else>
                    <div class="container">
                        <div class="row">
                            <div class="col col-md-6">
                                <label for="game-title" class="text-muted"
                                    >Title</label
                                >
                                <b-form-input
                                    id="game-title"
                                    class="font-weight-light"
                                    readonly
                                    v-model="game.title"
                                    placeholder="Please enter the game title..."
                                >
                                </b-form-input>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col col-md-8">
                                <label for="game-description" class="text-muted"
                                    >Description</label
                                >
                                <b-form-textarea
                                    id="game-description"
                                    class="font-italic font-weight-light"
                                    readonly
                                    v-model="game.description"
                                    placeholder="Please enter the game description here..."
                                    rows="3"
                                >
                                </b-form-textarea>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col text-muted">
                                <div class="row mt-3">
                                    <div class="col">
                                        <span>Number of prior days</span>
                                        <b-form-input
                                            class="text-center font-weight-light"
                                            v-model="
                                                game.simulation.template.clock
                                                    .prior_days
                                            "
                                            :number="true"
                                            type="number"
                                            min="0"
                                            inline
                                        >
                                        </b-form-input>
                                    </div>
                                    <div class="col">
                                        <span>Number of play days</span>
                                        <b-form-input
                                            class="text-center font-weight-light"
                                            v-model="
                                                game.simulation.template.clock
                                                    .play_days
                                            "
                                            :number="true"
                                            type="number"
                                            min="0"
                                            inline
                                        >
                                        </b-form-input>
                                    </div>
                                    <div class="col">
                                        <span>Number of post days</span>
                                        <b-form-input
                                            class="text-center font-weight-light"
                                            v-model="
                                                game.simulation.template.clock
                                                    .post_days
                                            "
                                            :number="true"
                                            type="number"
                                            min="0"
                                            inline
                                        >
                                        </b-form-input>
                                    </div>
                                    <div class="col">
                                        <span>Days per hour</span>
                                        <b-form-input
                                            class="text-center font-weight-light"
                                            v-model="
                                                game.simulation.template.clock
                                                    .days_per_hour
                                            "
                                            :number="true"
                                            type="number"
                                            min="0"
                                            step="0.5"
                                            inline
                                        >
                                        </b-form-input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <label for="game-cash" class="text-muted"
                                    >Initial capital</label
                                >
                                <b-form-input
                                    id="game-cash"
                                    class="font-weight-light"
                                    v-model="
                                        game.simulation.template.plant.cash
                                    "
                                    :number="true"
                                    type="number"
                                    min="0"
                                    inline
                                >
                                </b-form-input>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <label for="game-cash" class="text-muted"
                                    >Random seed</label
                                >
                                <b-form-input
                                    id="game-cash"
                                    class="font-weight-light"
                                    v-model="game.simulation.template.seed"
                                    :number="true"
                                    type="number"
                                    inline
                                >
                                </b-form-input>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col">
                                <b-form-checkbox
                                    id="game-leaderboard"
                                    v-model="game.leaderboard"
                                    disabled
                                >
                                    Show leaderboard to all players
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Layout" lazy>
                <div class="row-md-8 mt-4">
                    <div v-if="loading" class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        Please wait. The layout is loading...
                    </div>
                    <div v-else>
                        <factory :game.sync="game" :editable="true"></factory>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import { GameStatus } from '@/views'
import ApiService from '@/services/api.service'

export default {
    data() {
        return {
            loading: true,
            game: null,
            teams: {
                table: {
                    data: [],
                    fields: ['name', 'created', 'cash'],
                },
            },
        }
    },
    computed: {
        newLayout: function () {
            return this.game.layout
        },
        abortable: function () {
            return (
                !!this.game &&
                [GameStatus.SUSPENDED, GameStatus.STARTED].includes(
                    this.game.status
                )
            )
        },
        runnable: function () {
            return !!this.game && this.game.status == GameStatus.INITIALIZED
        },
        canDeleteTemplate: function () {
            return (
                !!this.game &&
                this.game.designer.id == this.$store.state.auth.user.id
            )
        },
    },
    methods: {
        saveGame() {
            this.$bvModal
                .msgBoxConfirm('Are you sure?', {
                    title: 'Save New Game',
                    centered: true,
                })
                .then((success) => {
                    if (!success) {
                        return
                    }
                    const payload = {
                        title: this.game.title,
                        description: this.game.description,
                        simulation: this.game.simulation,
                        layout: this.game.layout,
                        leaderboard: this.game.leaderboard,
                        template_id: this.game.id,
                    }

                    ApiService.saveGame(payload).then(
                        (response) => {
                            this.$router
                                .push({ name: 'design' })
                                .catch((err) => {})
                        },
                        (error) => {
                            this.$bvToast.toast(error.response.data.message, {
                                title: 'Error',
                                toaster: 'b-toaster-top-center',
                                variant: 'danger',
                                solid: true,
                            })
                        }
                    )
                })
        },
        updateTemplate() {
            this.$bvModal
                .msgBoxConfirm('Are you sure?', {
                    title: 'Update Template',
                    centered: true,
                })
                .then((success) => {
                    if (!success) {
                        return
                    }
                    ApiService.updateTemplate(
                        this.game.id,
                        this.game.simulation
                    ).then(
                        (response) => {
                            this.$bvToast.toast(response.data.message, {
                                title: 'Information',
                                toaster: 'b-toaster-top-center',
                                variant: 'success',
                                solid: true,
                            })
                        },
                        (error) => {
                            this.$bvToast.toast(error.response.data.message, {
                                title: 'Error',
                                toaster: 'b-toaster-top-center',
                                variant: 'danger',
                                solid: true,
                            })
                        }
                    )
                })
        },
        deleteTemplate() {
            this.$bvModal
                .msgBoxConfirm('Are you sure?', {
                    title: 'Delete Template',
                    centered: true,
                })
                .then((success) => {
                    if (!success) {
                        return
                    }

                    ApiService.deleteTemplate(this.game.id).then(
                        (response) => {
                            this.$router
                                .push({ name: 'design' })
                                .catch((err) => {})
                        },
                        (error) => {
                            this.$bvToast.toast(error.response.data.message, {
                                title: 'Error',
                                toaster: 'b-toaster-top-center',
                                variant: 'danger',
                                solid: true,
                            })
                        }
                    )
                })
        },
        loadGameTemplate() {
            ApiService.getTemplate({ id: this.$route.params.tempId }).then(
                (response) => {
                    this.game = response.data.data
                    this.loading = false
                },
                (error) => {
                    this.$bvModal
                        .msgBoxOk(error.response.data.message, {
                            title: 'Error',
                            centered: true,
                        })
                        .then((success) => {
                            this.$router
                                .push({ name: 'design' })
                                .catch((err) => {})
                        })
                }
            )
        },
    },
    mounted() {
        this.loadGameTemplate()
    },
}
</script>
